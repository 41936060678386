import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, SectionBox, FormRadioGroup, Loader } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import PortalLayout from '../../../../layouts/PortalLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { acceptLegalNoticesThunk, isBusySelector } from '../../../../store/participant';
import { PRIVACY_VERSION, TERMS_VERSION } from '../../../../core/constants/content.constants';

function CommsScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);

  const validationSchema = yup.object().shape({
    commsAccepted: yup.string().required(t('forms.commsAccepted.msg.required') as string),
  });

  const formik = useFormik({
    initialValues: { commsAccepted: '' },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        acceptLegalNoticesThunk({
          termsAccepted: true,
          privacyAccepted: true,
          communicationOptin: values.commsAccepted === 'Y' ? true : false,
          termsVersion: TERMS_VERSION,
          privacyVersion: PRIVACY_VERSION,
        }),
      );
    },
  });

  return (
    <PortalLayout>
      <SectionBox>
        <Typography textAlign="center" variant="h5" component="h1" paragraph>
          {t('forms.commsAccepted.label')}
        </Typography>
        <Typography textAlign="center" variant="body1" component="p" paragraph>
          {t('forms.commsAccepted.text')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormRadioGroup
              error={formik.errors.commsAccepted}
              name="commsAccepted"
              options={
                t('forms.commsAccepted.options', {
                  returnObjects: true,
                }) as any
              }
              onChange={formik.handleChange}
              touched={formik.touched.commsAccepted}
              value={formik.values.commsAccepted}
            />
          </Box>
          <ButtonRow
            buttonSize="medium"
            forwardColor="primary"
            forwardLabel={t('buttons.confirmComms') as string}
            showBack={false}
          />
        </form>
      </SectionBox>
      <Loader isVisible={isBusy} label={t('loaders.busy') as string} />
    </PortalLayout>
  );
}

export default CommsScene;
