import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { ODSRow } from '../../../../../../core/api/ods.types';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { TRANSLATION_VARS } from '../../../../../../core/constants/content.constants';

export interface PracticeBoxProps {
  practice?: ODSRow;
  hasPracticeError?: boolean;
  isBusy?: boolean;
}
function PracticeBox({ practice, hasPracticeError = false, isBusy = false }: PracticeBoxProps) {
  const { t } = useTranslation('portal');
  return (
    <Box mb={1} style={{ position: 'relative' }}>
      <Paper variant="outlined">
        <Box p={2}>
          <>
            <Typography paragraph variant="h6" component="h2">
              {t('signup.practiceTitle')}
            </Typography>
            {isBusy && (
              <Typography variant="body2">
                <Skeleton variant="text" width={210} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={170} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={80} />
              </Typography>
            )}

            {!isBusy && practice && (
              <>
                <Typography variant="body2">{practice?.Name}</Typography>
                <Typography variant="body2">{practice['Address Line 1']}</Typography>
                <Typography variant="body2">{practice['Address Line 2']}</Typography>
                <Typography variant="body2">{practice['Town']}</Typography>
                <Typography variant="body2">{practice['Postcode']}</Typography>
              </>
            )}
            {!isBusy && hasPracticeError && (
              <>
                <Typography variant="body1">
                  <ReactMarkdown linkTarget="_blank">
                    {t('signup.invalidOds', {
                      ...TRANSLATION_VARS,
                    })}
                  </ReactMarkdown>
                </Typography>
              </>
            )}
          </>
        </Box>
      </Paper>
    </Box>
  );
}

export default PracticeBox;
