/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import { Ancestry, AncestryGroup, AncestryRegion } from '../../../../../../core/api/portal.types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getColors, regionColors, useStyles } from '../../component.styles';
import { theme } from '@omnigenbiodata/ui';

export interface AncestryListProps {
  ancestryData: Ancestry | null;
}

function AncestryList({ ancestryData }: AncestryListProps) {
  const classes = useStyles();
  const [groupedData, setGroupedData] = useState<AncestryRegion[]>([]);

  useEffect(() => {
    if (ancestryData && ancestryData.status === 'complete') {
      const regions = ancestryData.regions
        .filter(({ groups }) => groups.length > 0)
        .map((region: AncestryRegion) => {
          let percentage = 0;
          region.groups.map((item: AncestryGroup) => {
            percentage += item.percentage;
          });
          return {
            ...region,
            percentage,
          };
        });
      setGroupedData(regions);
    }
  }, [ancestryData]);

  return groupedData ? (
    <List>
      {groupedData.map((region: AncestryRegion, regionIndex: number) => (
        <div key={region.geneticRegion} data-testid={region.geneticRegion}>
          <ListItem
            sx={{
              bgcolor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
            css={classes.ancestry}
            secondaryAction={
              <ListItemText
                primary={region.percentage?.toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 1,
                })}
                sx={{ textAlign: 'end', pr: 1 }}
              />
            }
          >
            <svg width={16} height={16} fill={regionColors[regionIndex]}>
              <circle cx={8} cy={8} r={8} />
            </svg>
            <ListItemText sx={{ pl: 2 }} primary={region.geneticRegionLabel} />
          </ListItem>
          <List sx={{ bgcolor: theme.palette.info.dark, color: theme.palette.primary.contrastText }}>
            {region.groups.map(({ group, label, percentage }: AncestryGroup, groupIndex: number) => (
              <ListItem
                sx={{ mx: 1 }}
                key={group}
                data-testid={group}
                css={classes.ancestry}
                secondaryAction={
                  <ListItemText
                    primary={Number(percentage).toLocaleString(undefined, {
                      style: 'percent',
                      minimumFractionDigits: 1,
                    })}
                    sx={{ textAlign: 'end' }}
                  />
                }
              >
                <svg width={16} height={16} fill={getColors(regionIndex, groupIndex, region.groups.length)}>
                  <circle cx={8} cy={8} r={8} />
                </svg>
                <ListItemText sx={{ pl: 2 }} primary={label} />
              </ListItem>
            ))}
          </List>
        </div>
      ))}
    </List>
  ) : null;
}

export default AncestryList;
