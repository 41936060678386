/** @jsxImportSource @emotion/react */
import { KeyboardEvent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import { useAuth } from '@omnigenbiodata/ui';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'react-i18next';
import { RiHome2Line, RiArrowDropRightFill, RiLogoutBoxLine, RiCloseLine } from 'react-icons/ri';
import { useStyles } from './component.styles';
import ROUTES from '../../../core/constants/routes.constants';
import { FEATURES } from '../../../core/constants/features.constants';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from '../../../store';
import { hasSuccessSelector as hasAncestrySelector } from '../../../store/ancestry';

interface SideMenuProps {
  isOpen: boolean;
  onToggle: (open: boolean) => (event: MouseEvent | KeyboardEvent) => void;
}

function SideMenu({ isOpen, onToggle }: SideMenuProps) {
  const { t } = useTranslation('portal');
  const { signOut } = useAuth();
  const classes = useStyles();
  const hasAncestry = useAppSelector(hasAncestrySelector);

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={onToggle(false)} data-testid="menu-drawer">
        <Box css={classes.drawer} role="presentation" onClick={onToggle(false)} onKeyDown={onToggle(false)}>
          <Toolbar disableGutters css={classes.toolbar}>
            <IconButton
              onClick={() => {
                onToggle(true);
              }}
              edge="end"
              data-testid="menu-close-btn"
            >
              <RiCloseLine />
            </IconButton>
          </Toolbar>
          <List>
            <ListItem
              data-testid="nav-item-home"
              button
              key={t('navigation.home') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.root}
            >
              <ListItemIcon css={classes.iconWrapper}>
                <RiHome2Line fontSize="inherit" css={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('navigation.home')} />
            </ListItem>
            <Flags authorizedFlags={[FEATURES.SAMPLES_SUBMIT, FEATURES.SAMPLES_HOLDING]}>
              <ListItem
                data-testid="nav-item-samples"
                button
                key={t('navigation.samples') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalSamples}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.samples')} />
              </ListItem>
            </Flags>
            <Flags authorizedFlags={[FEATURES.EHR]}>
              <ListItem
                data-testid="nav-item-ehr"
                button
                key={t('navigation.ehr') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalEhr}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.ehr')} />
              </ListItem>
            </Flags>
            <>
              {hasAncestry && (
                <Flags authorizedFlags={[FEATURES.ANCESTRY]}>
                  <ListItem
                    data-testid="nav-item-ancestry"
                    button
                    key={t('navigation.ancestry') as string}
                    component={Link}
                    onClick={() => {
                      onToggle(false);
                    }}
                    to={ROUTES.portalAncestry}
                  >
                    <ListItemIcon css={classes.iconWrapper}>
                      <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={t('navigation.ancestry')} />
                  </ListItem>
                </Flags>
              )}
            </>
            <Flags authorizedFlags={[FEATURES.USER_PREFERENCES]}>
              <ListItem
                data-testid="nav-item-prefs"
                button
                key={t('navigation.prefs') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalPrefs}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.prefs')} />
              </ListItem>
            </Flags>
            <Flags authorizedFlags={[FEATURES.USER_PROFILE]}>
              <ListItem
                data-testid="nav-item-profile"
                button
                key={t('navigation.profile') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalProfile}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.profile')} />
              </ListItem>
            </Flags>
            <Flags authorizedFlags={[FEATURES.PORTAL_PIS]}>
              <ListItem
                data-testid="nav-item-pis"
                button
                key={t('navigation.pis') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalPIS}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.pis')} />
              </ListItem>
            </Flags>
            <Flags authorizedFlags={[FEATURES.PORTAL_VIDEO]}>
              <ListItem
                data-testid="nav-item-video"
                button
                key={t('navigation.video') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalVideo}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.video')} />
              </ListItem>
            </Flags>
            <Flags authorizedFlags={[FEATURES.PORTAL_VIDEO]}>
              <ListItem
                data-testid="nav-faq"
                button
                key={t('navigation.faq') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalFAQ}
              >
                <ListItemIcon css={classes.iconWrapper}>
                  <RiArrowDropRightFill fontSize="inherit" css={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('navigation.faq')} />
              </ListItem>
            </Flags>
          </List>
          <Divider />
          <List>
            <ListItem
              data-testid="nav-logout"
              button
              key={t('navigation.logout') as string}
              component={'button'}
              onClick={(event: MouseEvent) => {
                signOut();
                event.preventDefault();
              }}
            >
              <ListItemIcon css={classes.iconWrapper}>
                <RiLogoutBoxLine fontSize="inherit" css={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('navigation.logout') as string} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default SideMenu;
