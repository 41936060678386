import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';
import { scaleLinear } from 'd3';

export const regionColors = ['#35aca2', '#58bbe5', '#ea4f6e', '#f2b020', '#8d4bd9', '#b03386', '#d8d34e'];
export const contrastColors = ['#e0dd0b', '#c1ffab', '#c39af5', '#bd1919', '#adc7ff', '#4643b0', '#9c794c'];

export const getColors = (
  regionIndex: number,
  groupIndex: number,
  groupLength: number = 5,
  highlightedGroup: number | null = null,
) =>
  highlightedGroup === groupIndex
    ? '#fffff'
    : scaleLinear<string>()
        .domain([0, groupLength + 2])
        .range([regionColors[regionIndex!], contrastColors[regionIndex!]])(groupIndex! + 1);

export const useStyles = () => {
  return {
    ancestry: css`
      path,
      line {
        stroke: ${theme.palette.primary.lighter};
        fill: ${theme.palette.primary.lighter};
        stroke-width: 0.5px;
      }
      text {
        font-size: 0.5rem;
      }
      .percentage {
        stroke-width: 0.5px;
      }
      .hidden {
        fill: none;
        stroke: none;
      }
      .label {
        fill: white;
        font-weight: 600;
      }
    `,
  };
};
