/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useMemo, useState } from 'react';
import { select } from 'd3';
import { useStyles } from '../../component.styles';
import Box from '@mui/material/Box';
import { Ancestry, AncestryRegion } from '../../../../../../core/api/portal.types';
import { drawGroups, drawWorldMap } from '../../utils/map.utils';

export interface AncestryMapProps {
  ancestryData: Ancestry | null;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
}

function AncestryMap({ ancestryData }: AncestryMapProps) {
  const ref = useRef(null as unknown as SVGSVGElement);
  const [map, setMap] = useState<any>();
  const classes = useStyles();

  useEffect(() => {
    if (ref.current) {
      const svg = select<SVGSVGElement, null>(ref.current);
      const map = svg.append('g');

      map.call(drawWorldMap);

      setMap(map);
    }
  }, []);

  const matchedLocations = useMemo(() => {
    return ancestryData
      ? ancestryData.regions
          .filter(({ groups }) => groups.length > 0)
          .reduce<AncestryLocation[]>((list, item: AncestryRegion, index: number) => {
            for (let i = 0; i < item.groups.length; i++) {
              for (const location of item.groups[i].locations) {
                list.push({
                  ...location,
                  group: item.groups[i].label,
                  regionIndex: index,
                  groupIndex: i,
                  groupLength: item.groups.length,
                });
              }
            }
            return list;
          }, [])
      : [];
  }, [ancestryData]);

  useEffect(() => {
    if (ancestryData && ancestryData.status === 'complete') {
      if (map) {
        drawGroups(matchedLocations, map);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, ancestryData]);
  return (
    <Box css={classes.ancestry} data-testid="ancestry-map">
      <svg width="100%" height="100%" viewBox="50 -50 900 500" preserveAspectRatio="xMaxYMax meet" ref={ref} />
    </Box>
  );
}

export default AncestryMap;
