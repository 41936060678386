import { feature } from 'topojson-client';
import { Topology } from 'topojson-specification';
import { geoNaturalEarth1, geoPath } from 'd3';
import { getColors } from '../component.styles';
import data_ from './world.json';

interface AncestryLocation {
  lat: number;
  lng: number;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
}

const data: Topology = data_ as unknown as Topology;

const projection = geoNaturalEarth1();
const pathGenerator = geoPath().projection(projection);
const countries: any = feature(data, data.objects.countries);

/* draws world map */
export const drawWorldMap = (selection: any) => {
  selection
    .selectAll('path')
    .data(countries.features)
    .enter()
    .append('path')
    .attr('d', (d: any) => pathGenerator(d));
};

export const drawGroups = (locations: AncestryLocation[], selection: any) => {
  /* creates mask around boundaries of world map */
  selection.append('defs').append('clipPath').attr('id', 'mapMask').call(drawWorldMap);

  /* creates blur filter  */
  selection.append('defs').append('filter').attr('id', 'blur').append('feGaussianBlur').attr('stdDeviation', '8');

  /* plots group locations on map */
  selection
    .append('g')
    .selectAll('circle')
    .data(locations)
    .enter()
    .append('circle')
    .style('stroke', (d: AncestryLocation) => getColors(d.regionIndex, d.groupIndex, d.groupLength))
    .style('fill', (d: AncestryLocation) => getColors(d.regionIndex, d.groupIndex, d.groupLength))
    .attr('cx', (d: any) => projection([d.lng, d.lat])![0])
    .attr('cy', (d: any) => projection([d.lng, d.lat])![1])
    .attr('r', 13)
    .style('filter', 'url(#blur)')
    .attr('clip-path', 'url(#mapMask)')
    .append('title')
    .text((d: any) => {
      return d.group;
    });
};
