import i18next from 'i18next';
import portalEn from '../../../translations/en/portal.json';
import pisEn from '../../../translations/en/pis.json';
import questionsEn from '../../../translations/en/questions.json';
import termsEn from '../../../translations/en/terms.json';
import privacyEn from '../../../translations/en/privacy.json';
import faqEn from '../../../translations/en/faq.json';
import awaitingEhrEn from '../../../translations/en/awaitingEhr.json';
import sampleCollectionEn from '../../../translations/en/sampleCollection.json';

export const setupI18next = (defaultLanguage = 'en') => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: defaultLanguage,
    fallbackLng: 'en',
    resources: {
      en: {
        portal: portalEn,
        pis: pisEn,
        questions: questionsEn,
        terms: termsEn,
        privacy: privacyEn,
        faq: faqEn,
        sampleCollection: sampleCollectionEn,
        awaitingEhr: awaitingEhrEn,
      },
    },
  });
  return i18next;
};
