import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { BiDna } from 'react-icons/bi';
import { SiGumtree } from 'react-icons/si';
import { BsHeart } from 'react-icons/bs';
import { HiOutlineDocument } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import { RxPerson } from 'react-icons/rx';
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from 'react-icons/ai';
import { MdOndemandVideo } from 'react-icons/md';
import PortalLayout from '../../../../layouts/PortalLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { hasSuccessSelector, responseSelector } from '../../../../store/participant';
import {
  hasSuccessSelector as hasAncestrySelector,
  isBusySelector as ancestryBusySelector,
} from '../../../../store/ancestry';
import { ehrOptinSelector } from '../../../../store/preferences';
import { theme, PressBox, Loader } from '@omnigenbiodata/ui';
import { Typography } from '@mui/material';
import { Flags } from 'react-feature-flags';
import { FEATURES } from '../../../../core/constants/features.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import { useTranslation } from 'react-i18next';
import { getParticipantConsentFormThunk } from '../../../../store/consentForm';
import {
  responseSelector as pdfLinkSelector,
  isBusySelector as pdfLinkIsBusySelector,
} from '../../../../store/consentForm';
import OpenFile from '../../../../components/common/OpenFile';

function HomeScene() {
  const { t } = useTranslation('portal');
  const [consentDownloaded, setConsentDownloaded] = useState(false);
  const hasProfile = useAppSelector(hasSuccessSelector);
  const profile = useAppSelector(responseSelector);
  const consentPdfLink = useAppSelector(pdfLinkSelector);
  const pdfLinkIsBusy = useAppSelector(pdfLinkIsBusySelector);
  const hasAncestry = useAppSelector(hasAncestrySelector);
  const ancestryBusy = useAppSelector(ancestryBusySelector);
  const ehrOptin = useAppSelector(ehrOptinSelector);
  const dispatch = useAppDispatch();

  const handleConsentFormDownload = () => {
    setConsentDownloaded(true);
    dispatch(getParticipantConsentFormThunk());
  };

  const handleConsentFormDownloaded = () => {
    setConsentDownloaded(false);
  };

  return (
    <PortalLayout>
      <OpenFile url={consentPdfLink} isReady={consentDownloaded} onOpen={handleConsentFormDownloaded} />
      {hasProfile && (
        <Box mb={3} textAlign={{ xs: 'center', sm: 'left' }}>
          <Typography variant="h4" color={theme.palette.primary.main} align="inherit">
            {t('home.welcome')}
            {profile?.firstName}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        <Flags authorizedFlags={[FEATURES.SAMPLES_SUBMIT, FEATURES.SAMPLES_HOLDING]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<BiDna color={theme.palette.primary.main} />} to={ROUTES.portalSamples}>
              {t('home.samples.line1')}
              <br />
              {t('home.samples.line2')}
            </PressBox>
          </Grid>
        </Flags>
        <Flags authorizedFlags={[FEATURES.CONSENT_FORM]}>
          <Grid item xs={6} sm={6}>
            <PressBox
              icon={<HiOutlineDocument color={theme.palette.warning.main} />}
              onClick={handleConsentFormDownload}
            >
              {t('home.consent.line1')}
              <br />
              {t('home.consent.line2')}
            </PressBox>
          </Grid>
        </Flags>
        {ehrOptin && (
          <Flags authorizedFlags={[FEATURES.EHR]}>
            <Grid item xs={6} sm={6}>
              <PressBox icon={<BsHeart color={theme.palette.error.main} />} to={ROUTES.portalEhr}>
                {t('home.ehr.line1')}
                <br />
                {t('home.ehr.line2')}
              </PressBox>
            </Grid>
          </Flags>
        )}
        {hasAncestry && (
          <Flags authorizedFlags={[FEATURES.ANCESTRY]}>
            <Grid item xs={6} sm={6}>
              <PressBox icon={<SiGumtree color={theme.palette.primary.main} />} to={ROUTES.portalAncestry}>
                {t('home.ancestry.line1')}
                <br />
                {t('home.ancestry.line2')}
              </PressBox>
            </Grid>
          </Flags>
        )}
        <Flags authorizedFlags={[FEATURES.USER_PROFILE]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<RxPerson color={theme.palette.info.main} />} to={ROUTES.portalProfile}>
              {t('home.profile.line1')}
              <br />
              {t('home.profile.line2')}
            </PressBox>
          </Grid>
        </Flags>
        <Flags authorizedFlags={[FEATURES.USER_PREFERENCES]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<FiSettings color={theme.palette.secondary.main} />} to={ROUTES.portalPrefs}>
              {t('home.prefs.line1')}
              <br />
              {t('home.prefs.line2')}
            </PressBox>
          </Grid>
        </Flags>
        <Flags authorizedFlags={[FEATURES.PORTAL_PIS]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<AiOutlineInfoCircle color={theme.palette.primary.main} />} to={ROUTES.portalPIS}>
              {t('home.pis.line1')}
              <br />
              {t('home.pis.line2')}
            </PressBox>
          </Grid>
        </Flags>
        <Flags authorizedFlags={[FEATURES.PORTAL_VIDEO]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<MdOndemandVideo color={theme.palette.primary.main} />} to={ROUTES.portalVideo}>
              {t('home.video.line1')}
              <br />
              {t('home.video.line2')}
            </PressBox>
          </Grid>
        </Flags>
        <Flags authorizedFlags={[FEATURES.PORTAL_FAQ]}>
          <Grid item xs={6} sm={6}>
            <PressBox icon={<AiOutlineQuestionCircle color={theme.palette.primary.main} />} to={ROUTES.portalFAQ}>
              {t('home.faq.line1')}
              <br />
              {t('home.faq.line2')}
            </PressBox>
          </Grid>
        </Flags>
      </Grid>
      <Loader isVisible={pdfLinkIsBusy || ancestryBusy} label={`${t('loaders.waiting')}`} />
    </PortalLayout>
  );
}

export default HomeScene;
